import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {fetchData} from '../actions/actions'
import Nav from './Nav/Nav.jsx'
import Player from './Player/Player.jsx'
import Loader from './Loader'

import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"

import Home from './Home'
import PageEpisode from './PageEpisode/PageEpisode';
import PageShow from './PageShow/PageShow';
import PageHosts from './PageHosts';
import {settings} from '../settings'

function App() {
  const dispatch = useDispatch()
  const episodes = useSelector(({episodes}) => episodes)
  const isMenuOpen = useSelector(({isMenuOpen}) => isMenuOpen)
  const loading = useSelector(({loading}) => loading)

  useEffect(() => {
    dispatch(fetchData())
  }, [dispatch])

  return (
    <>
      {loading && <Loader />}
      <Router>
        {episodes && <div className={`${(isMenuOpen ? 'open' : '')} layout`}>
          <Nav />
          <Switch>
            <Route path="/hosts">
              <PageHosts title={settings.pageTitles.hosts} />
            </Route>
            <Route path="/show/:id">
              <PageShow title={settings.pageTitles.show} />
            </Route>
            <Route path="/episode/:id">
              <PageEpisode />
            </Route>
            <Route path="/">
              <Home title={settings.pageTitles.home} />
            </Route>
          </Switch>
        </div>}
      </Router>
      {episodes && <Player initial={episodes[0]} />}
    </>
  );
}

export default App;
