import React from 'react'
import {Row, Col, Layout} from 'antd'

export default function Header() {
  return (
    <Layout.Content>
      <header>
        <Row>
          <Col sm={20}><h2>Our wonderfull header text</h2></Col>
          <Col sm={4} className="text-right"><h1 className="accent">Orelo</h1></Col>
        </Row>
      </header>
    </Layout.Content>
  )
}