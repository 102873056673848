import React from 'react'
import {Link} from 'react-router-dom'
import {Card, Avatar, Icon} from 'antd'
import '../scss/host.scss'

const { Meta } = Card;

export default function Host({name, bio, role, avatar, social, related}) {
    

  return (
    <Card className="host"
      bordered={false}
      className="Host"
      >
      <div className="card-header">
        <div className="card-header-left">
          <Meta
            avatar={<Avatar src={avatar} />}
          />
        </div>
        <div className="card-header-right">
          <Meta
            title = {name}
            description = {`Role: ${role}`}
          />
            <div className="SocialButtons"> 
              {Object.keys(social).map(key =>
                <a href={social.key} target="_blank" rel="noopener noreferrer" key={key}>
                  <Icon type={key} />
                </a>          
              )}
            </div>          
        </div>
      </div>
      <div className="card-content">
        <p>{bio}</p>
      </div>
      <div className="related">
        {related.map((item, key) => (
           <Link to={`/show/${item.id}`} key={key}>{item.name}</Link>  
          ))}          
      </div>
    </Card>
  )
}