import React from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setPlaying} from '../../actions/actions'
import useEpisode from '../../hooks/useEpisode'
import './FeaturedEpisode.scss'
import PubDate from '../PubDate/PubDate'

export default function FeaturedEpisode() {
  const featured = useSelector(({episodes}) => {
    const ft = episodes.find(ep => ep.featured)
    return ft || episodes[0]
  })
  const id = featured ? featured.id : null
  const ep = useEpisode(id)
  const dispatch = useDispatch()

  const playEpisode = () => {
    dispatch(setPlaying(id))
  }

  return (
    <>
      {ep && <div className="FeaturedEpisode __card">
        <div className="fw-area z-top" onClick={playEpisode}></div>
        <div className="fw-bg" style={{backgroundImage: `url(${ep.thumb})`}}></div>
        <div className="__caption ep-caption">
          <div className="glass">
            <div className="caption-upper">
              <div className="flex">
                {/* todo: date */}
                <div className="date">
                  <PubDate date={ep.pub_date} outputFormat="d MMM y" inputFormat="yyyy-MM-dd"/>
                </div>
                <div className="duration">{ep.duration} min</div>
              </div>
            </div>
            <h2 className="ep-title">{ep.title}</h2>
            <h3 className="ep-author no-mar-b">{ep.host.name}</h3>
          </div>
        </div>
      </div>}
    </>
  )
}