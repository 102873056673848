import React from 'react';
import {Link} from 'react-router-dom'
import {useDispatch, useSelector} from 'react-redux'
import { Menu, Icon } from 'antd';
import {toggleMenu} from '../../actions/actions'
import './Nav.scss'
import Search from '../Search/Search'
import {navigation, socialButtons} from '../../settings'
import ShareButtons from '../ShareButtons/ShareButtons'

export default function Nav() {
  const dispatch = useDispatch()
  const isMenuOpen = useSelector(({isMenuOpen}) => isMenuOpen)

  const handleClick = () => {
    dispatch(toggleMenu())
  }

  return (
    <nav className="smoked" id="sidebar">
      <main>
        <Menu mode="vertical" className="primary-menu">
          {navigation.map((item, key) => (
            <Menu.Item key={key}>
              <Link to={item.path} title={item.title}>
                <Icon type={item.icon} />
                <span>{item.title}</span>
              </Link>
            </Menu.Item>
          ))}
        </Menu>
        <Search iconClick={handleClick} expand={isMenuOpen}></Search>
        <div className="divider" />
        <ShareButtons buttons={socialButtons}></ShareButtons>
      </main>
      <footer>
        <div className="divider" />
        
        <div onClick={handleClick} className="toggle pointer bold">
          {isMenuOpen ? <div><Icon type="double-left" /> Collapse</div> : <div><Icon type="double-right" /></div>}
        </div>
      </footer>
    </nav>
  )
}
