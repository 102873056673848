import React from 'react';
import { Icon } from 'antd';

export default function ShareBtn({network}) {
  let shareUrl = network.url.replace('<URL>', window.location.href).replace('<TITLE>', document.title)
  return (
    <a href={shareUrl} title={network.title} className="ShareBtn" target="_blank" rel="noopener noreferrer">
      <Icon type={network.icon} />
    </a>
  )
}