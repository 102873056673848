import React from 'react'

export default function RatioBox({bg, ratio = [1, 1]}) {
  try {
    const pad = Math.round(ratio[1] / ratio[0] * 100)

    return (
      <div className="ratio-box" style={{paddingBottom: pad}}>
        {bg && <img src={bg} className="fw-bg" />}
      </div>
    )
  } catch (_) {
    console.log('[RatioBox]: please make sure you specified correct ratio, e.g: [1, 1]')
    return (<></>)
  }
}