import React, { useRef, useEffect, useState } from 'react'
import {useSelector} from 'react-redux'
import { Button, Progress, Card, Avatar } from 'antd';
import ReactPlayer from 'react-player'
import Duration from './Duration'
import './Player.scss'

export default function Player({initial}) {
  const audio = useRef(null)
  const episode = useSelector(({episodes, playing}) => episodes.find(ep => ep.id === playing))
  const ep = episode ? episode : initial
  const author = useSelector(({hosts}) => hosts.find(host => host.id === parseInt(ep.author_id)))
  const { Meta } = Card;
  
  // Define Player Instance
  const [playerInstance, setPlayerInstance] = useState(null);
  // Ref callback function recieves player Instance
  const playerRef  = player => {
    setPlayerInstance(player)
  }
  // Define Playing status
  const [playing, setPlaying] = useState(false);
  // Define playing elapsed time
  const [played, setPlayed] = useState(0);
  // Define track duration
  const [duration, setDuration] = useState(0);
  
  // Event Handler for Play/Pause
  const handlePlayPause = () => {
    setPlaying(!playing)
  }
  // Event Handler for playing progress
  const handleProgress = state => {
    setPlayed(state.played)
  }  
  // Event handler for track duration
  const handleDuration = (duration) => {
    setDuration(duration)
  }  
  // Event handler for seek -10 seconds
  const hadlePrev10 = () => {
    playerInstance.seekTo(duration*played - 10,'seconds');
  }
  // Event handler for seek +10 seconds
  const hadleNext10 = () => {
    playerInstance.seekTo(duration*played + 10,'seconds');
  }  
  
  useEffect(() => {
    /* only if `playing` is set in the store
      we will autoplay the audio */
    episode && audio.current && audio.current.play()
  }, [episode])

  return (<div>{ep && <div className="fg flex" id="player">
    <Card className="TrackInfo" bordered={false}>
      <Meta
        avatar={<Avatar src={ep.thumb} />}
        title={ep.title}
        description={author.name}
      />      
    </Card>
    <div className="PlayerControls">  
      <Button shape="circle" icon="fast-backward" className="Prev10" 
        onClick={hadlePrev10}
      />
      <Button shape="circle" icon={playing ? "pause-circle" : "play-circle"} className="PlayButton" 
        onClick={handlePlayPause}
      />
      <Button shape="circle" icon="fast-forward" className="Next10"  
        onClick={hadleNext10}
      />
      <Duration seconds={duration * played} className="TimeElapsed" />
      
      <Progress 
        strokeLinecap="square" 
        percent={played * 100} 
        showInfo = {false}
        className="ProgressBar"
      />
      
      <Duration seconds={duration} className="TimeDuration"/>
      <Button icon="download" className="DownloadButton"/>      
      <Button icon="share-alt" className="ShareButton"/>      
    </div>
      
    <ReactPlayer
      ref={playerRef}
      url={ep.audio} 
      playing={playing}
      file="forceAudio" 
      onProgress={handleProgress}
      onDuration={handleDuration}
      className="PlayerAudio"
      />
  </div>}</div>)
}