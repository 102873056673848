import {useState, useEffect} from 'react'
import {useStore} from 'react-redux'

export default function useEpisode(id) {
  const [episode, setEpisode] = useState();
  const store = useStore()

  useEffect(() => {
    const {episodes, hosts, shows} = store.getState()
    const ep = episodes.find(ep => ep.id === parseInt(id))
    
    if (ep) {
      const show = shows.find(show => show.id === ep.show_id)
      const host = hosts.find(h => h.id === ep.author_id)
      
      setEpisode({
        ...ep,
        show,
        host
      })
    }
  }, [id, store])

  return episode
}