import React from 'react';
import {useParams} from 'react-router-dom'
import useEpisode from '../../hooks/useEpisode';
import {Layout, Row, Col, Button, Icon} from 'antd'
import {setPlaying} from '../../actions/actions';
import {useDispatch} from 'react-redux'
import useTitle from '../../hooks/useTitle';
import RatioBox from '../../common/ratioBox'
import Header from '../Header'
import StarRating from '../StarRating/StarRating';
import './PageEpisode.scss'

export default function EpisodePage() {
  const {id} = useParams()
  const episode = useEpisode(parseInt(id))
  
  useTitle(`${episode && episode.title} | ${episode && episode.show.title}`)
  const dispatch = useDispatch()

  if (!episode) return ''

  const playEpisode = () => {
    dispatch(setPlaying(episode.id))
  }

  return (
    <div>
    <Header />
      {episode && <Layout.Content>
        <Row className="SingleEpisode" gutter={25}>
          <Col sm={7} className="EpisodeLeft">
            <RatioBox bg={episode.thumb} />
          </Col>
          <Col sm={17} className="EpisodeRight">
            <h3 className="showTitle">{episode.show.title}</h3>
            <h2 className="episodeTitle">{episode.title}</h2>
            <div className="episodeCategory">{episode.show.category} &bull; <span className="episodeDuration">{episode.duration} min</span></div>
            <StarRating rating={episode.rating} />
            <div className="episodeDescription">
              <p>{episode.description}</p>
            </div>
            <div className="actions flex">
              <Button icon="play-circle" className="playButton">Play</Button>
              <Button icon="down-circle">Preload</Button>
              <Button icon="share-alt">Share</Button>
            </div>
            <div className="episodeNav flex j-between">
              <Button><Icon type="arrow-left" />Previous episode</Button>
              <Button>Next episode<Icon type="arrow-right" /></Button>
            </div>
          </Col>
        </Row>
      </Layout.Content>}
    </div>
  )
}