export default function base(state = [], action) {
  switch (action.type) {
    case 'SET_PLAYING':
      return {
        ...state,
        ...{playing: parseInt(action.id)}
      }
    case 'TOGGLE_MENU':
      return {
        ...state,
        ...{isMenuOpen: !state.isMenuOpen}
      }

    case 'SET_SEARCH':
      return {
        ...state,
        ...{search: action.term || ''}
      }
    case 'START_ASYNC':
      return {
        ...state,
        loading: true
      }
    case 'FINISH_ASYNC':
      return {
        ...state,
        loading: false
      }
    case 'SET_DATA':
      return {
        ...state,
        ...action.payload
      }
    case 'SET_EPISODES':
      return {
        ...state,
        episodes: [
          ...state.episodes,
          ...action.payload.episodes
        ]
      }
    case 'FETCH_DATA':
      return state
    case 'GET_HOSTS':
        return state.hosts
    case 'SET_HOSTS':
      return {
        ...state.hosts,
        ...action.payload
      }
    default:
      return state
  }
}