import React, {useState, useEffect} from 'react';
import {Carousel as ReactCarousel} from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import {Button, Icon} from 'antd'
import './Carousel.scss'

export default function Carousel(props) {
  
  return (
    <ReactCarousel
      showThumbs={false}
      {...props}
      >
      {props.children}
    </ReactCarousel>
  )  
}