import React, {useState, useEffect, useRef} from 'react'
import {AutoComplete, Icon} from 'antd';
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import './Search.scss'

export default function Search({expand, iconClick}) {
  const [search, setSearch] = useState()
  const history = useHistory()
  const [dataSource, setDataSource] = useState([])
  const episodes = useSelector(({episodes}) => episodes)
  const input = useRef()

  const handleChange = str => {
    setSearch(str)
  }

  const handleFocus = () => {
    !expand && iconClick()
  }

  const handleSelect = id => {
    history.push(`/episode/${id}`)
    setSearch('')
    input.current && input.current.blur()
  }

  useEffect(() => {
    if (!search) {
      setDataSource([])
      return
    }

    const eps = episodes.filter(ep => ep.title.toLowerCase().startsWith(search.toLowerCase()))

    setDataSource(eps.map(ep => ({
      text: ep.title,
      value: ep.id
    })))
  }, [search, episodes])

  return (
    <div className="Search">
      <AutoComplete ref={input} value={search} onFocus={handleFocus} onSelect={handleSelect} onChange={handleChange} placeholder="Search episodes" dataSource={dataSource} /><Icon className="magnifier" type="search" />
    </div>
  )
}