export const setData = data => ({
  type: 'SET_DATA',
  payload: data
})

export const setSearch = term => ({
  type: 'SET_SEARCH',
  term
})

export const startAsync = () => ({
  type: 'START_ASYNC'
})

export const finishAsync = () => ({
  type: 'FINISH_ASYNC'
})

export const setEpisodes = (payload) => {
  return {
    type: 'SET_EPISODES',
    payload
  }
}

export const fetchMore = showId => {
  return dispatch => {
    dispatch(startAsync())
    
    return fetch(`http://www.mocky.io/v2/5de00c913500008665480af7?showId=${showId}`)
      .then(d => d.json())
      .then(data => {
        dispatch(setEpisodes({
          ...data
        }))
      })
      .finally(() => dispatch(finishAsync()))
  }
}

export const loadMore = (showId, {episodes, show}) => {
  return dispatch => {
    const countEps = episodes.filter(ep => ep.show_id === showId)

    if (show.episodes <= countEps.length) {
      return {
        type: 'GET_ALL_EPISODES',
        showId
      }
    }

    return dispatch(fetchMore(showId))
  }
}

export const toggleMenu = () => ({type: 'TOGGLE_MENU'})

export const setPlaying = id => {
  return {
    type: 'SET_PLAYING',
    id
  }
}

export const fetchData = () => {
  return dispatch => {
    dispatch(startAsync())
    const pr = fetch('http://www.mocky.io/v2/5e2187522f0000670077d71d').then(res => res.json())
    
    pr.then(data => {
      dispatch(setData(data))
    })
    
    pr.finally(() => {
      dispatch(finishAsync())
    })
    
    return pr
  }
}