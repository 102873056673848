import React from 'react';
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import './Shows.scss'

import Carousel from '../Carousel/Carousel' 


function Show(props) {
  return (
    <div className="Show-card __card">
      <div className="fw-area z-top" onClick={(e) => props.handleClick(props.id)}></div>
      <div className="fw-bg" style={{backgroundImage: `url(${props.cover})`}}></div>
        <div className="Show-caption __caption">
        <div className="glass">
          <div className="caption-upper">
            Last episode {props.latest.pub_date}
          </div>
          <h2 className="ep-title">{props.title}</h2>
          <div className="desc">{props.description}</div>
        </div>
      </div>
    </div>
  )
}

export default function Shows() {
  const history = useHistory()
  const shows = useSelector(({shows, episodes}) => shows.map(show => ({
    ...show,
    latest: episodes.find(ep => ep.show_id === show.id)
  })), [])

  const handleClick = showId => history.push('/show/' + showId)

  return (
    <Carousel showIndicators={false} showStatus={false} showArrows={true}>
      {shows && shows.map(show => 
        <Show key={show.id} {...show} handleClick={handleClick} />
      )}
    </Carousel>
  )
}

