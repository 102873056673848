import React from 'react';
import Shows from './Shows/Shows'
import FeaturedEpisode from './FeaturedEpisode/FeaturedEpisode'
import {Layout} from 'antd'
import {settings} from '../settings'
import {useSelector} from 'react-redux'
import Episode from './Episode/Episode.jsx';
import Header from './Header'
import useTitle from '../hooks/useTitle';
import SubscriptionForm from './SubscriptionForm/SubscriptionForm'
const {Content} = Layout
const {about} = settings

export default function Home({title}) {
  useTitle(title)
  const eps = useSelector(({episodes}) => episodes)

  return (
    <div>
      <Header></Header>
      <Content className="workspace home page">
        <section>
          <div className="block">
            <h1 className="accent">{title}</h1>
            <Shows />
          </div>
          <div className="block">&lt;banner&gt;</div>

          <div className="block">
            <h1 className="accent">{settings.aboutTitle}</h1>
            {about}
          </div>
          
          <div className="block subscribe-block">
            <h2>Subscribe</h2>
            <SubscriptionForm />
          </div>
        </section>

        <aside className="fg ">
          <div className="block">
            <h1 className="accent">Featured Episode</h1>
            <FeaturedEpisode />
          </div>
          
          <div className="block">
            {eps && eps.map(ep => <Episode key={ep.id} {...ep} />)}
          </div>
        </aside>
      </Content>
    </div>
  )
}
