import React from 'react'
import {useSelector} from 'react-redux'
import Host from './Host'
import {Row, Col, Layout} from 'antd'
import useTitle from '../hooks/useTitle';
import Header from './Header'
const {Content} = Layout

export default function PageHosts({title}) {
  useTitle(title)
  const hosts = useSelector(({hosts}) => hosts)

  return (
      
    <div>
      <Header></Header>
      <Content className="workspace hosts">
        <section>
          <div className="block">
            <h1 className="accent">{title}</h1>
            <Row gutter={25} type="flex">
            {hosts && hosts.length && hosts.map(
                    host => 
                <Col key={host.id} xl={8} xxl={6} lg={10} md={12} sm={14}>
                    <Host {...host}/>
                </Col>
            )}
            </Row>
          </div>
        </section>
      </Content>
    </div>    
  )
}