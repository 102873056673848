/* Hi, Dear User.
  This is a place where we keep all our settings.
  For more details please visit our documentation: <link>
*/
export const settings = {
  title: 'Orelo \u2014',
  about: 'lorem10Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur earum, soluta pariatur enim expedita repellendus, dolorem quod laborum tempora fugiat iste libero sed eaque eos, cupiditate deleniti reprehenderit sunt deserunt?',
  aboutTitle: 'About Us',
  pageTitles: {
    home: 'Our Shows',
    show: 'Show:',
    hosts: 'Hosts'
  }
}

// Nice to see you here! This is where you edit your sidebar navigation
export const navigation = [{
  title: 'Home',
  icon: 'home',
  path: '/'
},
{
  title: 'Hosts',
  icon: 'team',
  path: '/hosts'
},
{
  title: 'About Orelo',
  icon: 'crown',
  path: '/about'
}]

// Share Buttons
export const socialButtons = [
    {
        network: 'share',
        icon: 'share-alt',
        url: '#',
        title: 'Share'
    },
    {
        network: 'twitter',
        icon: 'twitter',
        url: 'https://twitter.com/intent/tweet?text=<TITLE> <URL>',
        title: 'Share on Twitter'
    },
    {
        network: 'facebook',
        icon: 'facebook',
        url: 'http://www.facebook.com/share.php?u=<URL>&p[title]=<TITLE>',
        title: 'Share on Facebook'
    },
    {
        network: 'linkedin',
        icon: 'linkedin',
        url: 'http://www.linkedin.com/shareArticle?mini=true&url=<URL>&title=<TITLE>',
        title: 'Share on Linkedin'
    }        
]