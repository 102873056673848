import React from 'react';
import {useParams} from 'react-router-dom'
import useTitle from '../../hooks/useTitle';
import {useSelector, useDispatch} from 'react-redux'
import Episode from '../Episode/Episode.jsx'
import {Layout, Row, Col} from 'antd'
import { loadMore } from '../../actions/actions';
import { arrayJoin } from '../../utils';
import StarRating from '../StarRating/StarRating'
import Header from '../Header';
const {Content} = Layout

export const ShowDetails = ({show, authors}) => {
  return (
    <Row gutter={30}>
      <Col sm={12}>
        <figure>
          <img className="radius-30" src={show.cover} alt={show.title} />
        </figure>
      </Col>
      <Col sm={12}>
        <section className="page-Show-details">
          <h6>{show.category}</h6>
          <h2>{show.title}</h2>
          <p><small>by {arrayJoin(authors, ', ', ' and ' )}</small></p>
          <StarRating rating={4} />
          <p className="show-description">{show.description}</p>
        </section>
      </Col>
    </Row>
  )
}

export default function PageShow({title}) {
  const {id} = useParams();
  const dispatch = useDispatch()
  const episodes = useSelector(({episodes}) => episodes.filter(ep => ep.show_id === parseInt(id)), [id])
  const show = useSelector(({shows}) => shows.find(sh => sh.id === parseInt(id)), [id])
  const authors = useSelector(({hosts}) => hosts.filter(host => show.team.includes(host.id))).map(a => a.name)
  
  useTitle(`${title}${show && show.title}`)

  const loadAllEpisodes = () => {
    dispatch(loadMore(id, {episodes, show}))
  }

  return (
    <div>
    <Header />
      {show && 
        <Content className="page">
          <h1>{title} {show.title}</h1>
          <ShowDetails authors={authors} show={show} />
          <h2 className="accent">Latest Episodes</h2>
          <div className="divider mb-15"></div>
          <>
            {episodes && episodes.length && episodes.map(episode => 
              <div key={episode.id}>
                <Episode layout="row" {...episode} />
                <div className="divider mb-15"></div>
              </div>
            )}
          </>
        </Content>
      }
    </div>
  )
}
