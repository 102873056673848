import React from 'react';
import ShareBtn from './ShareBtn'
import './ShareButtons.scss'

export default function ShareButtons({buttons}) {
    return (
        <div className="ShareButtons"> 
            {buttons.map((item, key) => ( 
                <ShareBtn network={item} key={key}/>
            ))}
        </div>
    )
}
