import React, { useState, useEffect } from 'react';
import { parse, format, formatISO9075 } from 'date-fns'

export default function PubDate({date, outputFormat, inputFormat, className}) {
  
  const [parsedDate, setParsedDate] = useState(parse(date, inputFormat, new Date()));
  const [dateTime, setDateTime] = useState(null);
  const [outputStringDate,setOutputStringDate] = useState(null);
  
  useEffect(() => {
    setDateTime(formatISO9075(parsedDate, { representation: 'date' }));
    setOutputStringDate(format(parsedDate, outputFormat));
  },[date]);
  
  return (
    <time 
      dateTime={dateTime} 
      className={className}>
      {outputStringDate}
    </time>
  )
}