import React from 'react';
import {useDispatch, useSelector} from 'react-redux'
import {setPlaying} from '../../actions/actions';
import {Row, Col, Button, Icon} from 'antd'
import './Episode.scss'
import PubDate from '../PubDate/PubDate'

function EpisodeCard({id, author, title, duration, playing, handleClick, thumb, pub_date}) {
  return (
    <div className="Episode-Card __card">
      <div className="fw-area z-top" onClick={handleClick}></div>
      <div className="fw-bg" style={{backgroundImage: `url(${thumb})`}}></div>
      <div className="__caption ep-caption">
        <div className="glass">
          <div className="caption-upper">
            <div className="flex">
              {/* todo: date */}
              <div className="date">
                <PubDate date={pub_date} outputFormat="d MMM y" inputFormat="yyyy-MM-dd"/>
              </div>
              <div className="duration">{duration} min</div>
            </div>
          </div>
          <div className="strong">{title}</div>
          <h3 className="ep-author no-mar-b">{author.name}</h3>
        </div>
      </div>
    </div>
  )
}

function EpisodeRow({handleClick, id, playing, thumb, title, pub_date, duration, author, description}) {
  return (
    <div className={`Episode-Row ${playing === id && ' playing'}`}>
      <Row>
        <Col sm={4}>
          <figure>
            <div className="ratio-box">
              <div className="fw-bg radius-30" style={{backgroundImage: `url(${thumb})`}} alt={title}></div>
            </div>
          </figure>
        </Col>
        <Col sm={20}>
          <header className="Episode-Row-header">
            <div className="flex">
              <div className="flex-1">
                <div className="heading flex j-between">
                  <div className="meta flex">
                    <div className="date">
                      <PubDate date={pub_date} outputFormat="d MMM y" inputFormat="yyyy-MM-dd"/>
                    </div>&bull;
                    <div className="duration">{duration} min</div>
                  </div>
                  <div className="actions flex">
                    <Button  onClick={handleClick} icon="play-circle">Play</Button>
                    <Button icon="down-circle">Preload</Button>
                  </div>    
                </div>
                <h5 className="title">{title}</h5>
                <div className="author">{author.name}</div>
                <div className="description">{description}</div>
              </div>
            </div>
          </header>
        </Col>
      </Row>
    </div>
  )
}

function EpisodeList({author, id, title, duration, playing, handleClick, pub_date}) {
  return (
    <div onClick={handleClick} className={`Episode-list ${playing === id && ' playing'}`}>
      <div className="flex v-center">
        <div className="ep-thumb">
          <img className="radius-8" src="https://via.placeholder.com/150x150" alt=""/>
        </div>
        <div className="ep-prime">
          <div className="strong">{title}</div><small>{author.name}</small>
        </div>
        <div className="ep-pubdate">
          <PubDate date={pub_date} outputFormat="d MMM y" inputFormat="yyyy-MM-dd"/>
        </div>
        <div className="ep-duration">{duration} min</div>
      </div>
    </div>
  )
}

export default function Episode(props) {
  const {id, author_id, layout = 'list'} = props
  const dispatch = useDispatch()
  const author = useSelector(({hosts}) => hosts.find(host => host.id === parseInt(author_id)))
  const playing = useSelector(({playing}) => playing)

  const handleClick = _ => {
    dispatch(setPlaying(id))
  }
  const components = {
    list: EpisodeList,
    card: EpisodeCard,
    row: EpisodeRow,
  }

  const RenderComponent = components[layout]

  return (
    <RenderComponent id={id} author={author} playing={playing} handleClick={handleClick} {...props} />
  )
}