import React, { useState } from 'react';
import { Form, Input } from 'antd';
import './SubscriptionForm.scss'

const { Search } = Input;

export default function SubscriptionForm({}) {
  
  const statuses = ['active','loading','error'];
  const [status, SetStatus] = useState(statuses[0]);
  
  return (
    <Form layout="inline" className="subscription-form">
      <Search
        placeholder="Your E-mail"
        enterButton="Subscribe"
        size="large"
        onSearch={(value) => SetStatus('loading')}
      />
    </Form>
  )
}